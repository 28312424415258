import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Tire Flips`}</p>
    <p>{`then,`}</p>
    <p>{`5 Tire Flips`}</p>
    <p>{`Sled Push 140ft (90/45)`}</p>
    <p>{`3 Rounds of:`}</p>
    <p>{`15-Thrusters (95/65)`}</p>
    <p>{`15-K2E’s`}</p>
    <p>{`Sled Push 140ft`}</p>
    <p>{`5 Tire Flips`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Open WOD 20.2 will be released today at 8:00pm at
Games.CrossFit.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      